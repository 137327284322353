<template>
  <div class="container-fluid">
    <div v-if="!searchItemComponentShow" class="row gutter-5">
      <div class="col-md-12">
        <div class="card mb-0">
          <div class="card-header border-bottom">
            <h4 class="mb-0">
              Warehouse {{ userWarehouseName }}
            </h4>
          </div>
          <div v-if="showMain" class="card-body py-1">
            <div class="row match-height">
              <div class="col-xl-4 col-lg-6 col-md-12 order-1 align-items-stretch">
                  <div class="card border">
                    <div class="card-body">
                      <h4 class="card-title">
                        Search & List Items
                      </h4>
                      <div class="form-row">
                        <div class="form-group col-md-8 mb-md-0">
                          <label for="source-location">Scan Code or Location</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span id="source-location-addon" class="input-group-text">
                                <i class="bi bi-upc-scan"></i>
                              </span>
                            </div>
                            <input v-model="searchItemQuery" type="text" name="source-location" class="form-control" aria-describedby="help-source-location" @keyup.enter="onEventSearhItem">
                          </div>
                        </div>
                        <div class="col-md-4 d-flex">
                          <button type="botton" class="btn btn-primary btn-block align-self-end" @click="onEventSearhItem()">
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card border">
                    <div class="card-header pb-0">
                      <h4 class="mb-0">
                        <i class="bi bi-box-seam-fill text-warning"></i> LPN
                      </h4>
                      <div class="heading-elements">
                        <ul class="list-inline mb-0">
                          <li>
                            <button type="botton" class="btn btn-primary btn-block align-self-end" @click="onClickGoToLpn()">
                              LPN Management
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-12 col-md-12 order-xl-2 order-lg-3 order-md-3 align-items-stretch">
                  <div class="card border">
                    <div class="card-header">
                      <h4 class="card-title">
                        Move Batch List
                      </h4>
                      <div class="heading-elements">
                      <ul class="list-inline mb-0">
                         <li class="mr-half">
                            <dx-switch
                              v-model="showOnlyOpenMoveBatchesStatus"
                              :height="36"
                              :width="80"
                              switched-off-text="CLOSED"
                              switched-on-text="OPEN"
                              hint="Show only OPEN shipment plans"
                              @value-changed="onChangeGetClosedMoveBatch"
                            />
                          </li>
                          <li class="mr-half">
                            <dx-util-text-box
                              v-model="filterMoveBatchText"
                              :show-clear-button="true"
                              mode="text"
                              placeholder="Search"
                              @enter-key="onSearchMoveBatchText"
                            />
                          </li>
                          <li class="mr-half">
                            <dx-util-button
                              type="success"
                              styling-mode="contained"
                              icon="add"
                              @click="onClickCreateMoveBatch"
                            />
                          </li>
                          <li>
                            <dx-util-button
                              type="default"
                              styling-mode="contained"
                              icon="pulldown"
                              @click="onReloadMoveBatchList"
                            />
                          </li>
                      </ul>
                      </div>
                    </div>
                    <div class="card-body">
                      <div v-if="moveBatches.length === 0">
                        <div class="alert alert-secondary" role="alert">
                          No active move batch
                        </div>
                      </div>
                      <div v-for="item in moveBatches" :key="item.id" class="d-flex flex-md-row flex-sm-column mb-1">
                        <div class="mr-auto align-bottom">
                          {{ item.name }}
                          <span class="badge badge-warning">{{ item.location }}</span>
                        </div>
                        <div class="mt-md-0 mt-sm-1">
                          <ul class="list-inline mb-0">
                            <li>
                              <dx-util-button
                                type="default"
                                styling-mode="contained"
                                icon="bulletlist"
                                class="mr-half"
                                @click="onClickShowMoveBatchItems($event, item.id)"
                              />
                            </li>
                            <li>
                              <dx-util-button
                                type="danger"
                                styling-mode="contained"
                                icon="trash"
                                :visible="item.status === moveBatchStatusEnum.OPEN.key"
                                @click="onClickDeleteMoveBatch(item.id)"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-12 order-xl-3 order-lg-2 order-md-2  align-items-stretch">
                  <div class="card border">
                    <div class="card-body">
                      <h4 class="card-title">
                        Quick Move Item
                      </h4>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="item-code required">Seach Item</label>
                          <input v-model="quickMove.code" type="text" name="item-code" class="form-control" placeholder="UPC, ASIN" @keyup.enter="onEventSearhItemQuickMove">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="item-code">Quantity</label>
                          <input v-model="quickMove.quantity" type="number" name="item-code" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="source-location">Source Location</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span id="source-location-addon" class="input-group-text">
                                <i class="bi bi-upc-scan"></i>
                              </span>
                            </div>
                            <input v-model="quickMove.sourceLocation" type="text" name="source-location" class="form-control" aria-describedby="help-source-location">
                          </div>
                          <small id="help-source-location" class="text-muted">Scan our enter source location code</small>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="target-location">Target Location</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span id="target-location-addon" class="input-group-text">
                                <i class="bi bi-upc-scan"></i>
                              </span>
                            </div>
                            <input v-model="quickMove.targetLocation" type="text" name="target-location" class="form-control" aria-describedby="help-target-location">
                          </div>
                          <small id="help-target-location" class="text-muted">Scan our enter target location code</small>
                        </div>
                      </div>
                      <div class="d-flex flex-row justify-content-between">
                        <button type="botton" class="btn btn-primary" @click="onClickMoveItem($event)">
                          Move
                        </button>
                        <button type="botton" class="btn btn-danger" @click="onClickMoveItemClear($event)">
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div v-if="(!showMain && showSearchContent)" class="card-body py-1">
            <div class="row">
              <div class="col-md-12">
                <dx-data-grid
                  id="searchLocationResultGrid"
                  ref="searchLocationResultGridRef"
                  :data-source="SearchLocationDataSource"
                  :allow-column-reordering="false"
                  :allow-column-resizing="false"
                  :column-auto-width="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :show-borders="true"
                  :hover-state-enabled="true"
                  :row-alternation-enabled="false"
                  :customize-columns="customizeColumns"
                  @initialized="onInitialized"
                  @content-ready="onContentReady"
                >
                <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
                <template #imageCellTemplate="{data}">
                  <b-img-lazy :rounded="true" width="72px" height="72px" :src="data.value || defaultImg" />
                </template>
                  <dx-column data-field="location" width="auto" />
                  <dx-column data-field="title" width="100%" />
                  <dx-column data-field="accountNo" width="auto" />
                  <dx-column data-field="storeName" width="auto" />
                  <dx-column data-field="asin" width="auto" />
                  <dx-column data-field="upc" width="auto" />
                  <dx-column data-field="fnsku" width="auto" />
                  <dx-column data-field="msku" width="auto" />
                </dx-data-grid>
              </div>
            </div>
          </div>
          <div v-if="(!showMain && showMoveableItemContent)" class="card-body py-1">
            <div class="row">
              <div class="col-md-12 mb-half">
                <div class="form-inline">
                  <div class="form-group mr-1">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="target-location-addon" class="input-group-text">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
                            <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                          </svg>
                        </span>
                      </div>
                      <input type="text" name="target-location" class="form-control" aria-describedby="help-target-location">
                    </div>
                  </div>
                  <button type="botton" class="btn btn-primary mr-1">
                    Release
                  </button>
                  <button type="botton" class="btn btn-danger" @click="onClickCancelMoveableItems($event)">
                    Cancel
                  </button>
                </div>
              </div>
              <div class="col-md-12">
                <dx-data-grid
                  id="searchLocationResultGrid"
                  ref="searchLocationResultGridRef"
                  :data-source="SearchLocationDataSource"
                  :allow-column-reordering="false"
                  :allow-column-resizing="false"
                  :column-auto-width="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :show-borders="true"
                  :hover-state-enabled="true"
                  :row-alternation-enabled="false"
                  :customize-columns="customizeColumns"
                  @initialized="onInitialized"
                  @content-ready="onContentReady"
                >
                <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
                <template #imageCellTemplate="{data}">
                  <b-img-lazy :rounded="true" width="72px" height="72px" :src="data.value || defaultImg" />
                </template>
                  <dx-column data-field="location" width="auto" />
                  <dx-column data-field="title" width="100%" />
                  <dx-column data-field="accountNo" width="auto" />
                  <dx-column data-field="storeName" width="auto" />
                  <dx-column data-field="asin" width="auto" />
                  <dx-column data-field="upc" width="auto" />
                  <dx-column data-field="fnsku" width="auto" />
                  <dx-column data-field="msku" width="auto" />
                </dx-data-grid>
              </div>
            </div>
          </div>
          <div v-if="showCreateMoveBatchContent" class="card-body py-1">
            <div class="row">
              <div class="col-md-12">
                <h4 class="card-title">
                  Create Move Batch
                </h4>
               <create-move-batch :component-id="createMoveBatchComponentId" @emit-close-move-batch-form="onEmitCloseMoveBatchForm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row gutter-5">
      <div class="col-md-12">
        <item-location :component-id="searchItemComponentId" :query="searchItemQuery" :divider="1.6" :actions="false" @on-hide-search-item-component="onEmitHideSearchItemComponent" />
      </div>
    </div>
    <div v-if="quickMoveItemLocations.showComponent" class="row gutter-5">
      <div class="col-md-12">
        <item-location :component-id="quickMoveItemLocations.componentId" :query="quickMoveItemLocations.query" :divider="2" :actions="true" @on-hide-search-item-component="onEmitHideSearchItemComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import { DxSwitch } from 'devextreme-vue/switch'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { v4 as uuidv4 } from 'uuid'
import { Notify } from '@robustshell/utils/index'
import moveBatchService from '@/http/requests/warehouseLocation/move-batch'
import inventoryService from '@/http/requests/inventory/inventoryService'
import useCurrentUser from '@/libs/app/current-user'
import moveStatusEnum from '@/enums/wms-move-status.enum'
import MoveBatchSearchFilter from '@/http/models/search-filters/MoveBatchSearchFilter'
import Pager from '@core/dev-extreme/utils/pager'
import { SearchLocationDataSource } from './components/search-location.store'
import CreateMoveBatch from './components/CreateMoveBatch.vue'
import ItemLocation from './components/ItemLocation.vue'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    CreateMoveBatch,
    DxSwitch,
    ItemLocation,
  },
  mixins: [GridBase],
  setup() {
    const { userWarehouseId, userWarehouseName } = useCurrentUser()
    return {
      userWarehouseId,
      userWarehouseName,
    }
  },
  data() {
    return {
      moveBatchStatusEnum: moveStatusEnum,
      moveBatches: [],
      defaultImg: defaultImageUrl,
      SearchLocationDataSource,
      showMain: true,
      showSearchContent: false,
      showMoveableItemContent: false,
      searchItemCode: '',
      createMoveBatchComponentId: '',
      showCreateMoveBatchContent: false,
      showOnlyOpenMoveBatchesStatus: true,
      filterMoveBatchStatus: moveStatusEnum.OPEN.key,
      filterMoveBatchText: '',
      searchItemComponentId: '',
      searchItemComponentShow: false,
      searchItemQuery: '',
      quickMove: {
        code: '',
        selectedItemId: null,
        quantity: 0,
        targetLocation: '',
        sourceLocation: '',
      },
      quickMoveItemLocations: {
        showComponent: false,
        componentId: '',
        query: '',
      },
    }
  },
  watch: {
    searchItemCode(newValue) {
      if (!newValue) {
        this.showMain = true
        this.showSearchContent = false
      }
    },
  },
  async mounted() {
    await this.getMoveBatchList()
  },
  methods: {
    async getMoveBatchList() {
      MoveBatchSearchFilter.warehouseId = this.userWarehouseId
      MoveBatchSearchFilter.moveBatchId = null
      MoveBatchSearchFilter.status = this.filterMoveBatchStatus
      MoveBatchSearchFilter.location = this.filterMoveBatchText
      const filters = MoveBatchSearchFilter.getFilters()
      const pager = new Pager({})
      const result = await moveBatchService.fetchListByWarehouseId(pager.staticPageable, filters)
      this.moveBatches.length = 0
      const { data } = result
      this.moveBatches = [...data]
    },
    async onSearchMoveBatchText() {
      await this.getMoveBatchList()
    },
    async onChangeGetClosedMoveBatch(e) {
      this.filterMoveBatchStatus = this.showOnlyOpenMoveBatchesStatus ? moveStatusEnum.OPEN.key : moveStatusEnum.MOVED.key
      await this.getMoveBatchList()
    },
    async onReloadMoveBatchList() {
      this.showOnlyOpenMoveBatches = true
      await this.getMoveBatchList()
    },
    onEnterSearchLocation(e) {
      this.showMain = false
      this.showSearchContent = true
    },
    onClickShowMoveBatchItems(e, id) {
      this.showMain = false
      this.showMoveableItemContent = true
      this.$router.push({ name: 'warehouse-move-batch', params: { moveBatchId: id } })
    },
    async onClickDeleteMoveBatch(id) {
      this.$swal({
        title: 'Are you sure you want to delete this Move Batch record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            await moveBatchService.delete(id)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Move Batch record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(async () => {
              await this.getMoveBatchList()
            })
          } catch (err) {
            const messeage = err.message || err
            Notify.error(messeage)
          }
        }
      })
    },
    onClickCancelMoveableItems(e) {
      this.showMain = true
      this.showMoveableItemContent = false
    },
    onClickCreateMoveBatch(e) {
      this.showCreateMoveBatchContent = true
      this.createMoveBatchComponentId = uuidv4()
    },
    async onEmitCloseMoveBatchForm() {
      this.showCreateMoveBatchContent = false
      this.createMoveBatchComponentId = ''
      await this.getMoveBatchList()
    },

    onEventSearhItem() {
      if (this.searchItemQuery) {
        this.searchItemComponentShow = true
        this.searchItemComponentId = uuidv4()
      }
    },
    onEmitHideSearchItemComponent(data) {
      this.searchItemComponentShow = false
      this.searchItemComponentId = ''
      this.quickMoveItemLocations.showComponent = false
      this.quickMoveItemLocations.query = ''
      if (data) {
        this.quickMove.selectedItemId = data.id
        this.quickMove.quantity = data.quantity
        this.quickMove.sourceLocation = data.locationName
      }
    },
    onClickGoToLpn() {
      this.$router.push({ name: 'warehouse-location-lpn' })
    },
    onEventSearhItemQuickMove() {
      if (this.quickMove.code) {
        this.quickMoveItemLocations.showComponent = true
        this.quickMoveItemLocations.componentId = uuidv4()
        this.quickMoveItemLocations.query = this.quickMove.code
      }
    },
    onClickMoveItem(e) {
      if (!this.quickMove.code || this.quickMove.code.trim().length === 0) {
        Notify.error('Item Code is required fields')
        return
      }
      if (!this.quickMove.targetLocation || this.quickMove.targetLocation.trim().length === 0) {
        Notify.error('Target Location is required fields')
        return
      }
      if (this.quickMove.quantity < 1) {
        Notify.error('Quantity should be at least 1')
      }
      const params = {
        id: this.quickMove.selectedItemId,
        warehouseId: this.userWarehouseId,
        location: this.quickMove.targetLocation.trim(),
        quantity: this.quickMove.quantity,
      }
      inventoryService.moveInventoryByLocation(params).then(result => {
        if (result.status === 200) {
          Notify.success('Items have been moved to new location successfully.')
          this.onClickMoveItemClear()
        }
      })
    },

    onClickMoveItemClear(e) {
      this.quickMove = {
        code: '',
        quantity: 0,
        selectedItemId: null,
        sourceLocation: '',
        targetLocation: '',
      }
    },
  },
}
</script>

<style>

</style>
