/* eslint-disable prefer-rest-params */
import find from 'lodash/find'
/**
 * Enum for Warehouse Move Status Enums.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const moveStatusEnum = Object.freeze({
  OPEN: { key: 'OPEN', value: 'OPEN', text: 'Open' },
  MOVED: { key: 'MOVED', value: 'MOVED', text: 'Moved' },
})

function getMoveStatusEnum(predicate, predicateKey = 'key') {
  const result = find(moveStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListMoveStatusEnum() {
  const result = Object.keys(moveStatusEnum).map(key => moveStatusEnum[key])
  return result
}

export default moveStatusEnum

export {
  getMoveStatusEnum,
  getListMoveStatusEnum,
}
