import ArrayStore from 'devextreme/data/array_store'
import DataSource from 'devextreme/data/data_source'

const data = [
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1554,
    productId: 123660057,
    smallImage: 'https://m.media-amazon.com/images/I/41rQSkSoP-L._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/61HXFNZ9rbL.jpg',
    amzRank: 1762,
    productType: 'SUNSCREEN',
    companyId: 123658384,
    suiteNo: '1554',
    asin: 'B07DR5816K',
    upc: null,
    fnsku: null,
    msku: 'msku1',
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 0,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: 124684557,
    notes: null,
    storeId: 123658555,
    title: 'Dr. Jart+ Cicapair Tiger Grass Color Correcting Treatment SPF30 50ml / 1.7oz',
    catalogItem: null,
    companyName: 'Company16',
    palletId: null,
    serialNo: null,
    sentCount: 3,
    moveReason: null,
    priceList: null,
    storeName: 'ALDE Goods',
    reserved: 0,
    locationName: null,
    location: '1RA12',
    id: 123660057,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1554,
    productId: 123660067,
    smallImage: null,
    largeImage: null,
    amzRank: null,
    productType: null,
    companyId: 123658384,
    suiteNo: '1554',
    asin: 'B07662VTLX',
    upc: '769915193893',
    fnsku: null,
    msku: 'msku4',
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 0,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: 124684556,
    notes: null,
    storeId: 123658555,
    title: 'The Ordinary Resveratrol 3% + Ferulic Acid 3% Antioxidant 1 fl oz / 30ml',
    catalogItem: null,
    companyName: 'Company16',
    palletId: null,
    serialNo: null,
    sentCount: 5,
    moveReason: null,
    priceList: null,
    storeName: 'ALDE Goods',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123660067,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123662835,
    smallImage: null,
    largeImage: null,
    amzRank: null,
    productType: null,
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B00FPZRE22',
    upc: '659658280939',
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 2,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123660354,
    title: "Nike Women's Benassi Just Do It Slide Sandal, Black/Black, 10",
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 3,
    moveReason: null,
    priceList: null,
    storeName: 'Prime Shipping Fast',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123662835,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123663208,
    smallImage: 'https://m.media-amazon.com/images/I/31aG4EhUQtL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/5128AZ-6ZpL.jpg',
    amzRank: 36,
    productType: 'SANDAL',
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B07T2KN1VC',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 0,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123660354,
    title: "Nike Unisex-Kid's Kawa Slide (GS/PS) Sandal, Black/Metallic Silver-Particle Grey, 13C Child US Little Kid",
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 7,
    moveReason: null,
    priceList: null,
    storeName: 'Prime Shipping Fast',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123663208,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123663231,
    smallImage: null,
    largeImage: null,
    amzRank: null,
    productType: null,
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B07T4QDBN3',
    upc: '193654861018',
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 5,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123660354,
    title: 'Nike Unisex-Child Kawa Slide (Gs/Ps) Sandal Black/Metallic Silver-particle Grey 1',
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 4,
    moveReason: null,
    priceList: null,
    storeName: 'Prime Shipping Fast',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123663231,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123663284,
    smallImage: 'https://m.media-amazon.com/images/I/31JnoeF7yBL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/71SZlQoFohL.jpg',
    amzRank: 15,
    productType: 'SANDAL',
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B003AQMJSW',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 2,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123660354,
    title: "Nike Men's Benassi Just Do It Athletic Sandal, Black/White, 12 D US",
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 5,
    moveReason: null,
    priceList: null,
    storeName: 'Prime Shipping Fast',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123663284,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123663454,
    smallImage: 'https://m.media-amazon.com/images/I/51sTg8Ca40L._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/71hORIBav9L.jpg',
    amzRank: 225,
    productType: 'SHOES',
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B089Q8MLB1',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 1,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123660354,
    title: 'Nike Womens Air Max Torch 4 Running Shoe (7.5, White/Black-Volt-Laser Crimson)',
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'Prime Shipping Fast',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123663454,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123673622,
    smallImage: 'https://m.media-amazon.com/images/I/41CNe4JqPnL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/61wEzwmtyPL.jpg',
    amzRank: 3,
    productType: 'DRINKING_CUP',
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B0761YD99J',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 4,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123660354,
    title: 'YETI Rambler 20 oz Tumbler, Stainless Steel, Vacuum Insulated with MagSlider Lid, Brick Red',
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'Prime Shipping Fast',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123673622,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123673726,
    smallImage: 'https://m.media-amazon.com/images/I/419SoEQ5iXL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/71Lg+5o4l9L.jpg',
    amzRank: 73,
    productType: 'FACE_SHAPING_MAKEUP',
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B000HLTVTS',
    upc: null,
    fnsku: null,
    msku: 'ST_AD_76232',
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 958,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: 124718875,
    notes: null,
    storeId: 124668116,
    title: 'Clinique True Bronze Pressed Powder Bronzer, No. 02 Sunkissed, 0.33 Ounce',
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 1,
    moveReason: null,
    priceList: null,
    storeName: 'kazansofrasi',
    reserved: 42,
    locationName: null,
    location: null,
    id: 123673726,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1554,
    productId: 123673805,
    smallImage: 'https://m.media-amazon.com/images/I/31IkFRj-BZL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/610mMJ3pbWL.jpg',
    amzRank: 93,
    productType: 'SKIN_CLEANING_AGENT',
    companyId: 123658384,
    suiteNo: '1554',
    asin: 'B01MQZX7XX',
    upc: null,
    fnsku: null,
    msku: 'msku3',
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 5,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: 124684555,
    notes: null,
    storeId: 123658555,
    title: 'New! Acne Solutions Clinical Clearing Gel, 1 oz / 30 ml',
    catalogItem: null,
    companyName: 'Company16',
    palletId: null,
    serialNo: null,
    sentCount: 1,
    moveReason: null,
    priceList: null,
    storeName: 'ALDE Goods',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123673805,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1554,
    productId: 123674344,
    smallImage: 'https://m.media-amazon.com/images/I/31N0I48EDiL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/31N0I48EDiL.jpg',
    amzRank: 408,
    productType: 'SKIN_MOISTURIZER',
    companyId: 123658384,
    suiteNo: '1554',
    asin: 'B08CL4P2MR',
    upc: null,
    fnsku: null,
    msku: 'msku2',
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 1,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: 124684554,
    notes: null,
    storeId: 123658555,
    title: 'OLEHENRIKSEN Invigorating Night Transformation Gel 30 ml/1Fl.oz',
    catalogItem: null,
    companyName: 'Company16',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'ALDE Goods',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123674344,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1709,
    productId: 123679877,
    smallImage: 'https://m.media-amazon.com/images/I/51tEhowkDWL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/9174z3udp7L.jpg',
    amzRank: 18441,
    productType: 'COUNTERTOP_GRIDDLE_APPLIANCE',
    companyId: 123655702,
    suiteNo: '1709',
    asin: 'B07JFF9M5D',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 48,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123655802,
    title: 'Black Rock Grill Steak Stone, Hot Stone Grill - Sizzling Hot Rock, Indoor Grill, Cooking Stone with Matt Black Ramekins and Side Plate (One Standard Set)',
    catalogItem: null,
    companyName: 'Company8',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'black-rock-grill - SHOPIFY',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123679877,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1709,
    productId: 123679883,
    smallImage: null,
    largeImage: null,
    amzRank: null,
    productType: null,
    companyId: 123655702,
    suiteNo: '1709',
    asin: '',
    upc: '5060148930008',
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 30,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123655802,
    title: 'Stone Grill Sharing Set',
    catalogItem: null,
    companyName: 'Company8',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'black-rock-grill - SHOPIFY',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123679883,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1709,
    productId: 123679889,
    smallImage: 'https://m.media-amazon.com/images/I/31yFjlokrAS._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/81dmClmHY4S.jpg',
    amzRank: 0,
    productType: 'BAKING_STONE',
    companyId: 123655702,
    suiteNo: '1709',
    asin: 'B08S7HPNLS',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 40,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123655802,
    title: 'Black Rock Grill Large Bamboo Pizza Paddle Board, Wood Pizza Peel - 45cm x 34cm/ 17.72" x 13.3"',
    catalogItem: null,
    companyName: 'Company8',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'black-rock-grill - SHOPIFY',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123679889,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1709,
    productId: 123679895,
    smallImage: 'https://m.media-amazon.com/images/I/51O04M+lUwL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/91RRM1FpaAL.jpg',
    amzRank: 1418,
    productType: 'POT_HOLDER',
    companyId: 123655702,
    suiteNo: '1709',
    asin: 'B01M9FB3GN',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 20,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123655802,
    title: 'Heat & Temperature Resistant Gloves 500C/932F BBQ Aga Oven Silicon Safety',
    catalogItem: null,
    companyName: 'Company8',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'black-rock-grill - SHOPIFY',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123679895,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1709,
    productId: 123679903,
    smallImage: 'https://m.media-amazon.com/images/I/412DNM-NRrS._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/81FPND0b6TS.jpg',
    amzRank: 0,
    productType: 'CUTTING_BOARD',
    companyId: 123655702,
    suiteNo: '1709',
    asin: 'B08SMKQZ4C',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 33,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123655802,
    title: 'Black Rock Grill Large Bamboo Wooden Serving board, 44cm x 27cm x 3cm / 17.3" x 10.3" x 1.1", Large Steak Board',
    catalogItem: null,
    companyName: 'Company8',
    palletId: null,
    serialNo: null,
    sentCount: 15,
    moveReason: null,
    priceList: null,
    storeName: 'black-rock-grill - SHOPIFY',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123679903,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 123680421,
    smallImage: 'https://m.media-amazon.com/images/I/41XKIbrvD0L._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/81MagYsoRgL.jpg',
    amzRank: 23,
    productType: 'SHORTS',
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B07BPM5FL2',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 0,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123660354,
    title: "Nike Dri-FIT Icon, Men's Basketball Shorts, Athletic Shorts with Side Pockets, Cool Grey/Cool Grey/Black, 2XL",
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'Prime Shipping Fast',
    reserved: 0,
    locationName: null,
    location: null,
    id: 123680421,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1783,
    productId: 124655822,
    smallImage: 'https://m.media-amazon.com/images/I/41ElovL9YJL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/81XIBlUgolL.jpg',
    amzRank: 3664,
    productType: 'SPEAKERS',
    companyId: 123657107,
    suiteNo: '1783',
    asin: 'B07NSP78DF',
    upc: null,
    fnsku: null,
    msku: null,
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 1,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: null,
    notes: null,
    storeId: 123658329,
    title: 'iHome iBT158 Smart Bluetooth Speaker - with Color Changing LED Lights - Perfect Portable Audio Device for Parties, Outdoors, and Other Events',
    catalogItem: null,
    companyName: 'Company13',
    palletId: null,
    serialNo: null,
    sentCount: 0,
    moveReason: null,
    priceList: null,
    storeName: 'Skyway US',
    reserved: 0,
    locationName: null,
    location: null,
    id: 124655822,
  },
  {
    parentLocation: null,
    expirationDate: null,
    accountNo: 1579,
    productId: 124684478,
    smallImage: 'https://m.media-amazon.com/images/I/41aumiEg4CL._SL75_.jpg',
    largeImage: 'https://m.media-amazon.com/images/I/71mw25goenL.jpg',
    amzRank: 30,
    productType: 'SKIN_CLEANING_AGENT',
    companyId: 123660229,
    suiteNo: '1579',
    asin: 'B08DGGYF71',
    upc: null,
    fnsku: '',
    msku: '2854_B08DGGYF71_64oz_4pck',
    tracking: null,
    orderNumber: null,
    receivedCount: 0,
    quantity: 1881,
    warehouseName: 'DE-Newark-1',
    itemAction: 'FBM',
    warehouseId: 4101,
    planId: null,
    mskuFnskuPairId: 124697129,
    notes: null,
    storeId: 124668116,
    title: "Harry's Men's Body Wash - Shiso - 16 Fluid Ounce, Pack of 4 (Packaging May Vary)",
    catalogItem: null,
    companyName: 'Company17',
    palletId: null,
    serialNo: null,
    sentCount: 46,
    moveReason: null,
    priceList: null,
    storeName: 'kazansofrasi',
    reserved: 110,
    locationName: null,
    location: null,
    id: 124684478,
  },
]

const SearchLocationDataSource = new DataSource({
  store: new ArrayStore({
    key: 'id',
    data: data,
  }),
})

export {
  SearchLocationDataSource,
}
