<template>
  <form @submit="handleSubmit">
    <dx-util-form
      ref="moveBatchFromRef"
      name="moveBatchFrom"
      :form-data="formData"
      :col-count-by-screen="colCountByScreen"
      :screen-by-width="screenByWidth"
      col-count="auto"
      :show-colon-after-label="false"
      :scrolling-enabled="true"
      label-location="top"
    >
      <template #action-button-template="{}">
        <div class="d-flex justify-content-start justify-content-md-end">
          <div class="mr-half">
            <dx-util-button
              type="success"
              text="Save"
              width="100"
              @click="handleSubmit"
          />
          </div>
          <div>
            <dx-util-button
              type="danger"
              text="Cancel"
              width="100"
              @click="onClickCancel"
            />
          </div>
        </div>
      </template>
      <dx-util-simple-item data-field="location" editor-type="dxTextBox" />
      <dx-util-simple-item data-field="name" editor-type="dxTextBox">
        <dx-util-required-rule message="Name is required." />
      </dx-util-simple-item>
      <dx-util-simple-item template="action-button-template" css-class="align-self-md-end" :label="{ text:'Actions'}" />
    </dx-util-form>
  </form>
</template>
<script>
import moment from 'moment'
import useCurrentUser from '@/libs/app/current-user'
import moveStatusEnum from '@/enums/wms-move-status.enum'
import moveBatchService from '@/http/requests/warehouseLocation/move-batch'
import { Notify } from '@robustshell/utils/index'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      userWarehouseId, userWarehouseName, userFullname, userTenantId,
    } = useCurrentUser()
    return {
      userWarehouseId,
      userWarehouseName,
      userFullname,
      userTenantId,
    }
  },
  data() {
    return {
      formData: {
        id: null,
        location: '',
        warehouseId: 0,
        name: '',
        status: moveStatusEnum.OPEN.value,
      },
    }
  },
  computed: {
    colCountByScreen() {
      return {
        xs: 1,
        sm: 1,
        md: 3,
        lg: 3,
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.formData.name = this.createName()
        }
      },
    },
  },
  methods: {
    screenByWidth(width) {
      if (width < 576) return 'xs'
      if (width >= 576 && width < 768) return 'sm'
      if (width >= 768 && width < 992) return 'md'
      if (width >= 1200) return 'lg'
      return 'lg'
    },
    createName() {
      const now = new Date()
      const formatedDate = moment(now).format('YY-MM-DD HH:mm')
      const userName = this.userFullname.split(' ')[0].charAt(0) + this.userFullname.split(' ')[1].charAt(0)
      const name = `${this.userWarehouseName}-${userName} ${formatedDate}`
      return name
    },
    async handleSubmit() {
      const form = this.$refs.moveBatchFromRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const data = {
          id: this.formData.id,
          location: this.formData.location,
          warehouseId: this.userWarehouseId,
          name: this.formData.name,
          status: this.formData.status,
        }
        try {
          await moveBatchService.create(data)
          this.$emit('emit-close-move-batch-form')
        } catch (err) {
          const messeage = err.message || err
          Notify.error(messeage)
          this.$emit('emit-close-move-batch-form')
        }
      }
    },
    onClickCancel() {
      this.$emit('emit-close-move-batch-form')
    },
  },
}
</script>
