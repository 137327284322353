import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchListByWarehouseId(pageable, filter) {
    const result = await axios.post(endpoints.warehouseLocation.MoveBatchController.getList(pageable), filter)
    const data = result.data.body
    return {
      data: data.content,
      totalElements: data.totalElements,
    }
  },

  async create(data) {
    const result = await axios.post(endpoints.warehouseLocation.MoveBatchController.create(), data)
    return result
  },

  async delete(id) {
    const result = await axios.delete(endpoints.warehouseLocation.MoveBatchController.delete(id))
    return result
  },

  async moveAllItemsByBatchId(moveBatchId, location) {
    const result = await axios.put(endpoints.warehouseLocation.MoveBatchController.moveAllItemsByBatchId(moveBatchId, location))
    return result
  },

  async moveSelectedItems(data) {
    const result = await axios.put(endpoints.warehouseLocation.MoveBatchController.moveSelectedItems(), data)
    return result
  },
}
